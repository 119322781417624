import React, { Component } from "react";
import scriptLoader from "react-async-script-loader";

const Preview = ({ isScriptLoaded, isScriptLoadSucceed }) => {
    if (isScriptLoaded && isScriptLoadSucceed) {
        window.AirbnbAPI.bootstrap();
    }

    const isSmallScreen = window.innerWidth < 768; // Define your threshold as needed

    return (
        <div
            className="airbnb-embed-frame"
            data-id="790614281017932944"
            data-view="home"
            data-hide-price="false"
            style={{ width: "100%" }}
        >
            <a href="https://www.airbnb.com/rooms/790614281017932944?guests=1&amp;adults=1&amp;s=66&amp;source=embed_widget">View On Airbnb</a>

            <a href="https://www.airbnb.com/rooms/790614281017932944?guests=1&amp;adults=1&amp;s=66&amp;source=embed_widget" rel="nofollow">Place to stay in Ens</a>
        </div >
    );
};

export default scriptLoader(["https://www.airbnb.com/embeddable/airbnb_jssdk"])(
    Preview
);

