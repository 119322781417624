// Neshoeve.js

import React from 'react';
import './Neshoeve.css';
import Content from './Content';
import GrassComponent from './GrassComponent';

const Neshoeve = () => {
  return (
    <div className="content-container">
      <Content />
      <GrassComponent />
    </div>
  );
};

export default Neshoeve;
