// GrassComponent.js
import React from 'react';
import './Neshoeve.css';

import alpacaImage from './alpaca.gif'

const isSmallScreen = window.innerWidth < 768; // Define your threshold as needed

const getRandomRotation = () => {
    // Generate a random rotation between 0 and 45 degrees
    return Math.floor(Math.random() * 45);
};

const date = new Date();
let year = date.getFullYear();

const generateGrassBlades = (numberOfBlades) => {
    return [...Array(numberOfBlades)].map((_, index) => (
        <div
            key={index}
            className="grass-blade"
            style={{ transform: `rotate(${getRandomRotation()}deg)` }}
        ></div>
    ));
};

const GrassComponent = () => {
    const numberOfBlades = 420; // Adjust the number of grass blades

    return (
        <div className="grass">
            {generateGrassBlades(numberOfBlades)}
            <div className="grass-content">
                {isSmallScreen ? null :
                    <div className='alpaca-location'>
                        <img src={alpacaImage} alt='alpaca'></img>
                    </div>
                }
                <div className="centered-text">
                    ©{year} De Neshoeve. Schokkeringweg 37, 8307 RC Ens
                </div>
            </div>
        </div>);
};

export default GrassComponent;