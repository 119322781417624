// Content.js
import React from 'react';
import './Content.css';
import { Button, Tooltip } from '@mui/material';
import {
    FacebookRounded,
    Instagram,
    Hotel,
    Call,
    Mail,
    KeyboardDoubleArrowUp,
    Directions,
} from '@mui/icons-material';

import EmbedAirBnB from './EmbedAirBnB';
import logo from './neshoevelogo.jpg'

import ReactImageGallery from 'react-image-gallery';
// import stylesheet if you're not already using CSS @import
import "react-image-gallery/styles/css/image-gallery.css";

const Content = () => {

    const isSmallScreen = window.innerWidth < 768; // Define your threshold as needed
    const isMedScreen = window.innerWidth < 1024; // Define your threshold as needed

    const images = [
        {
            original: "https://neshoeve.nl/images/001.png",
            thumbnail: "https://neshoeve.nl/images/001_thumb.png",
        },
        {
            original: "https://neshoeve.nl/images/001.png",
            thumbnail: "https://neshoeve.nl/images/001_thumb.png",
        },
        {
            original: "https://neshoeve.nl/images/002.png",
            thumbnail: "https://neshoeve.nl/images/002_thumb.png",
        },
        {
            original: "https://neshoeve.nl/images/003.png",
            thumbnail: "https://neshoeve.nl/images/003_thumb.png",
        },
        {
            original: "https://neshoeve.nl/images/004.png",
            thumbnail: "https://neshoeve.nl/images/004_thumb.png",
        },
        {
            original: "https://neshoeve.nl/images/005.png",
            thumbnail: "https://neshoeve.nl/images/005_thumb.png",
        },
        {
            original: "https://neshoeve.nl/images/006.png",
            thumbnail: "https://neshoeve.nl/images/006_thumb.png",
        },
        {
            original: "https://neshoeve.nl/images/007.png",
            thumbnail: "https://neshoeve.nl/images/007_thumb.png",
        },
        {
            original: "https://neshoeve.nl/images/008.png",
            thumbnail: "https://neshoeve.nl/images/008_thumb.png",
        },
        {
            original: "https://neshoeve.nl/images/009.png",
            thumbnail: "https://neshoeve.nl/images/009_thumb.png",
        },
        {
            original: "https://neshoeve.nl/images/010.png",
            thumbnail: "https://neshoeve.nl/images/010_thumb.png",
        },
        {
            original: "https://neshoeve.nl/images/011.png",
            thumbnail: "https://neshoeve.nl/images/011_thumb.png",
        },
        {
            original: "https://neshoeve.nl/images/012.png",
            thumbnail: "https://neshoeve.nl/images/012_thumb.png",
        },
        {
            original: "https://neshoeve.nl/images/013.png",
            thumbnail: "https://neshoeve.nl/images/013_thumb.png",
        },
        {
            original: "https://neshoeve.nl/images/014.png",
            thumbnail: "https://neshoeve.nl/images/014_thumb.png",
        },
        {
            original: "https://neshoeve.nl/images/015.png",
            thumbnail: "https://neshoeve.nl/images/015_thumb.png",
        },
        {
            original: "https://neshoeve.nl/images/016.png",
            thumbnail: "https://neshoeve.nl/images/016_thumb.png",
        }
    ]
    return (
        <div className="container">
            <div className="left-column">
                <div className="middle-icons">
                    <div className="icons-wrapper">
                        <Tooltip title="Facebook">
                            <Button onClick={() => window.open("https://www.facebook.com/Neshoeve", "_blank")}>
                                <FacebookRounded fontSize="large" htmlColor='#00C9AD' />
                            </Button>
                        </Tooltip>

                        <Tooltip title="Instagram">
                            <Button onClick={() => window.open("https://www.instagram.com/neshoeve/", "_blank")}>
                                <Instagram fontSize="large" htmlColor='#00C9AD' />
                            </Button>
                        </Tooltip>

                        <Tooltip title="Airbnb">
                            <Button onClick={() => window.open(" https://www.airbnb.com/rooms/790614281017932944", "_blank")}>
                                <Hotel fontSize="large" htmlColor='#00C9AD' />
                            </Button>
                        </Tooltip>

                        <Tooltip title="Mail ons">
                            <Button onClick={() => window.open('mailto:info@neshoeve.nl', '_self')}>
                                <Mail fontSize="large" htmlColor='#00C9AD' />
                            </Button>
                        </Tooltip>

                        <Tooltip title="Route">
                            <Button onClick={() => window.open('https://maps.app.goo.gl/JHF5et8e7r2npmvJ8', '_blank')}>
                                <Directions fontSize="large" htmlColor='#00C9AD' />
                            </Button>
                        </Tooltip>
                    </div>
                </div>

                <Tooltip title="Terug omhoog">
                    <Button style={{ marginBottom: "24px" }} onClick={() => {
                        var myDiv = document.getElementById('middle');
                        myDiv.scrollTop = 0;
                    }}>
                        <KeyboardDoubleArrowUp fontSize="large" htmlColor='#00C9AD'></KeyboardDoubleArrowUp>
                    </Button>
                </Tooltip>
            </div>

            <div id="middle" className="right-column">
                <div key={0} className="panel" style={{ display: "flex", justifyContent: "center" }}>
                    <img style={{ height: isSmallScreen ? "100px" : "250px", justifyContent: "center" }} src={logo} alt='neshoevelogo' />
                </div>
                <div className="divider"></div>
                <div key={1} className="panel">
                    <div>
                        <p>
                            Welkom bij De Neshoeve, een charmante bed and breakfast gelegen aan de Schokkeringweg 37, 8307 RC Ens. Onze idyllische locatie bevindt zich in de nabijheid van Ens en Schokland, te midden van een rustgevend landschap. Naast het bieden van comfortabele accommodaties, verwelkomen wij onze gasten met een prachtige omgeving en diverse dieren om van te genieten.
                        </p>
                        <p>
                            Ontdek het plezier van een verblijf op onze boerderij, waar vriendelijke kippen, statige kalkoenen, speelse konijnen, en wendbare hazen een harmonieus deel uitmaken van onze gemeenschap. En laten we zeker onze geliefde Alpaca's niet vergeten. Bij De Neshoeve kun je zelfs wandelen met deze gracieuze dieren, waardoor je een unieke en onvergetelijke ervaring hebt. Ontsnap aan de dagelijkse drukte en omarm de rust en charme van De Neshoeve. We kijken ernaar uit om je te verwelkomen en je een bijzonder verblijf te bieden.
                        </p>
                    </div>
                </div>
                <div className="divider"></div>
                <div key={2} className="panel">
                    <div style={{ display: "flex", height: "auto", justifyContent: "center", marginTop: "8px", marginLeft: "8px" }}>
                        <ReactImageGallery autoPlay lazyLoad showNav={false} showThumbnails showFullscreenButton={false} showPlayButton={false} slideInterval={8000} items={images}></ReactImageGallery>
                    </div>
                </div>
                <div className="divider"></div>
                <div key={3} className="panel">
                    <div style={{ display: "flex" }}>
                        <div style={{ width: isSmallScreen ? "100%" : "75%" }}>
                            <EmbedAirBnB></EmbedAirBnB>
                        </div>
                        {isSmallScreen || isMedScreen ? null :
                            <div>
                                <p>Kom slapen in het nieuwe appartement bij ons op de Neshoeve. Nabij Schokland. Op korte afstand bevinden zich Kampen, Urk, Emmeloord en Ens. Met mooi weer is het heerlijk vertoeven op het Schokker strand. Voor een fijne wandeling en een bezoek aan het museum kunt u terecht op Schokland.</p>
                                <a href="https://www.airbnb.com/rooms/790614281017932944" target="_blank" rel="noopener noreferrer">
                                    <span>Bekijk op Airbnb</span>
                                </a>
                            </div>
                        }
                    </div>
                </div>
                {/* <div className="divider"></div> */}
                {/* <div key={4} className="panel">
                    <div>
                        <p>Bij de Neshoeve bieden we de volgende activiteiten aan:</p>
                        <ul>
                            <li>Wandelen met Alpaca's</li>
                            <li>Lunchen tussen de Alpaca's</li>
                            <li>--</li>
                            <li>--</li>
                            <li>--</li>
                        </ul>
                        <p>Neem gerust contact met ons op voor prijzen en meer informatie.</p>
                    </div>
                </div> */}
                <div className="divider" style={{ marginBottom: "16px" }}></div>
            </div>
        </div>

    );
};

export default Content;
